import User from "./User";
import FirebaseManager from "./FirebaseManager";
import ApoWheelEvents from "./ApoWheelEvents";

export default class ListManager {
  get enabledUserList(): User[] {
    return this._enabledUserList;
  }

  set enabledUserList(value: User[]) {
    this._enabledUserList = value;
  }

  get userList(): User[] {
    return this._userList;
  }

  set userList(value: User[]) {
    this._userList = value;
  }

  get dbConnectOpenModalButton(): HTMLCanvasElement {
    return this._dbConnectOpenModalButton;
  }

  static instance: ListManager;
  private listWrapper: HTMLElement | null = document.querySelector(".list-wrapper")!;
  private listEl: HTMLElement | null = document.querySelector(".list-wrapper ul")!;
  private liTemplate: HTMLTemplateElement | HTMLElement | null = document.getElementById(
    "list-item"
  );
  private selectAllButton: HTMLElement = document.querySelector(".select-all")!;
  private selectNoneButton: HTMLElement = document.querySelector(".select-none")!;
  private _dbConnectOpenModalButton: HTMLCanvasElement = document.querySelector(".btn.db-connect")!;
  private firebase: FirebaseManager;
  private _userList: User[];
  private _enabledUserList: User[];

  constructor(firebase: FirebaseManager) {
    this.firebase = firebase;
    this._userList = [];
    this._enabledUserList = [];

    this.registerListeners();


    if (ListManager.instance) {
      return ListManager.instance;
    }
    ListManager.instance = this;
  }

  private registerListeners = () => {
    this.selectAllButton.addEventListener('click', this.selectAllUsers);
    this.selectNoneButton.addEventListener('click', this.selectNoUsers);
    document.addEventListener(ApoWheelEvents.WHEEL_STOPPED, this.onWheelStop);
  }

  private onWheelStop = (e: Event) => {
    if ((e as CustomEvent).detail.winner) {
      (e as CustomEvent).detail.winner.numModerated++;
      this.firebase.hasUnsyncedData = true;
    } else return;

    // console.log('(e as CustomEvent).detail.winner = ', (e as CustomEvent).detail.winner);

    this.updateList();
    this.showEnabled();
  }

  private onListElementClick = (e: Event) => {
    const user: User = this.getUserByEl(e.currentTarget as HTMLElement)!;
    if (user) user.isEnabled = !user.isEnabled;

    document.dispatchEvent(new CustomEvent(ApoWheelEvents.USER_SELECTION_CHANGED, {
      detail: {
        enabledUserList: this._enabledUserList
      }
    }));
  };

  public selectAllUsers = () => {
    // console.log('selectAllUsers():');
    this._userList.forEach(user => {
      user.isEnabled = true;
    })

    document.dispatchEvent(new CustomEvent(ApoWheelEvents.USER_SELECTION_CHANGED, {
      detail: {
        enabledUserList: this._enabledUserList
      }
    }));

    // console.log('this.enabledUserList: ', this._enabledUserList);
  }

  public selectNoUsers = () => {
    this._userList.forEach(user => {
      user.isEnabled = false;
    })

    document.dispatchEvent(new CustomEvent(ApoWheelEvents.USER_SELECTION_CHANGED, {
      detail: {
        enabledUserList: this._enabledUserList
      }
    }));
  }

  private getUserByEl = (li: HTMLElement): User | null => {
    for (const user of this._userList) {
      if (user.el === li) return user;
    }
    return null;
  }

  // private getElByUser = (user: User): HTMLLIElement | null => {
  //   for (const listElChild of this.listEl!.querySelectorAll('li')) {
  //     if (user.el === listElChild) return listElChild;
  //   }
  //   return null;
  // }

  private sortUserlistByModeration(list: [] | User[]) {
    list.sort(function (x: User, y: User) {
      if (x.numModerated < y.numModerated) {
        return -1;
      }
      if (x.numModerated > y.numModerated) {
        return 1;
      }
      return 0;
    });
  }

  private updateList = () => {
    // console.log('updateList:')
    this.listEl!.innerHTML = '';
    this.enabledUserList = [];
    const count = this.userList.length;

    if (count <= 0) return;

    let updatedList: User[] = this.userList.concat();
    this.userList = [];

    // console.log('updatedList = ', updatedList);

    this.sortUserlistByModeration(updatedList);
    this.createUsersFromList(updatedList);
  }

  private createListElement = (user: User) => {
    const li: HTMLLIElement = document.createElement("li")!;
    li.appendChild((this.liTemplate as HTMLTemplateElement).content.cloneNode(true));
    li.classList.add("list-item");
    li.querySelector(".list-item__name")!.innerHTML = user.name;
    li.querySelector(".list-item__team")!.innerHTML = user.team;
    li.querySelector(".list-item__moderated")!.innerHTML = user.numModerated + '';
    li.addEventListener("click", this.onListElementClick);

    return li;
  }

  private createUsersFromList = (list: User[]) => {
    list.forEach((item: User) => {
      // console.log('user = ', user);
      // console.log('li = ', li);
      let user = new User(item.name, item.team, item.id, item.numModerated, this.enabledUserList);
      user.el = this.createListElement(item);
      this.userList.push(user);
      this.listEl?.appendChild(user.el);
    });
  }

  public populateList = () => {
    // console.log('populateList:')
    this.listEl!.innerHTML = '';
    this.enabledUserList = [];
    // if(this.userList.length === 0) this.userList = this.firebase.usersData;

    if (!this.firebase.usersData) return;

    // console.log('this.userList = ', this.userList);
    // console.log('this.firebase.usersData = ', this.firebase.usersData);

    this.sortUserlistByModeration(this.firebase.usersData);
    this.createUsersFromList(this.firebase.usersData);
  };

  public newEntry = async (name: string, team: string, numModerated: number): Promise<void> => {
    return new Promise((resolve) => {
      this.userList.push(new User(name, team, this.createUUID(), numModerated, this.enabledUserList));
      this.updateList();
      this.showEnabled();
      this.firebase.hasUnsyncedData = true;
      resolve();
    })
  }

  private createUUID(): string {
    let dt = new Date().getTime();
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }

  public showEnabled = () => {
    // console.log('show enabled():');

    if (!this.userList || this.userList.length <= 0) return;

    let lowestModerationNumber = this.userList.reduce(
      (min: any, p: any) => (p.numModerated < min ? p.numModerated : min),
      this.userList[0].numModerated
    );

    // console.log('lowestModerationNumber = ', lowestModerationNumber);

    // console.log('this.userList = ', this.userList);

    this.userList.forEach((user: User) => {
      // console.log('user.el = ', user.el);
      user.el?.classList.remove("enabled");
      if (!user.el) return;
      if (parseInt(user.el.querySelector(".list-item__moderated")!.innerHTML) == lowestModerationNumber) {
        if (user) user.isEnabled = true;
      }
    });

    document.dispatchEvent(new CustomEvent(ApoWheelEvents.USER_SELECTION_CHANGED, {
      detail: {
        enabledUserList: this._enabledUserList
      }
    }));

    // console.log('this.enabledUserList: ', this._enabledUserList);
  };

  public blockList = () => {
    this.listWrapper?.classList.add('disabled');
  }

  public unblockList = () => {
    this.listWrapper?.classList.remove('disabled');
  }

}
