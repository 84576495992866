import User from "./User";
// @ts-ignore
import distinctcolors from "distinct-colors";

export default class SpinningWheel {
  private _rgba2Hex: any;
  get overlayCanvas(): HTMLCanvasElement {
    return this._overlayCanvas;
  }

  set overlayCanvas(value: HTMLCanvasElement) {
    this._overlayCanvas = value;
  }

  // get ctx(): CanvasRenderingContext2D {
  //     return this._ctx;
  // }

  get wheelCanvas(): HTMLCanvasElement {
    return this._wheelCanvas;
  }

  get isSpinning(): boolean {
    return this._isSpinning;
  }

  get rotation(): number {
    return this._rotation;
  }

  set rotation(value: number) {
    this._rotation = value;
  }

  get velocity(): number {
    return this._velocity;
  }

  set velocity(value: number) {
    this._velocity = value;
  }

  get radius(): number {
    return this._radius;
  }

  set radius(value: number) {
    this._radius = value;
  }

  get sections(): number {
    return this._sections;
  }

  set sections(value: number) {
    this._sections = value;
  }

  get userList(): User[] {
    return this._userList;
  }

  set userList(userList) {
    this._userList = userList;
    this._userList.forEach((data) => {
      data.angle = 360 / (this._userList.length);
    })
    this.renderWheel();
  }

  private _sections: number = 0;
  private _radius: number = 0;
  private _userList: User[];
  private _velocity: number = 0;
  private _rotation: number = 0;
  private _isSpinning: boolean = false;
  private _increaseFactor: number = 0;
  private readonly COLOR_A: string = '#333'
  private readonly COLOR_APO_PRIMARY: string = '#039'
  // private readonly COLOR_B: string = '#62d6fc'
  private globalAlpha: number = 1;
  private _wheelCanvas: HTMLCanvasElement;
  private _overlayCanvas: HTMLCanvasElement;
  private _rotationAnimationID: number = -1;
  private _rainbowGradient: string[] = [
    "#FF0000",
    "#FF6000",
    "#FF9000",
    "#FFCC00",
    "#FFFF00",
    "#AAFF00",
    "#77EE00",
    "#33FF66",
    "#00FFAA",
    "#00FFFF",
    "#00CCFF",
    "#0090FF",
    "#0060FF",
    "#0030FF",
    "#3000FF",
    "#6000FF",
    "#9000FF",
    "#FF00FF",
    "#FF00AA",
    "#FF0044"
  ];

  constructor(wheelCanvas: HTMLCanvasElement, overlayCanvas: HTMLCanvasElement, radius: number, rgbaToHex : Function) {
    this._sections = 0;
    this._userList = [];
    this._velocity = 0;
    this._rotation = 0;
    this._radius = radius;
    this._wheelCanvas = wheelCanvas;
    this._overlayCanvas = overlayCanvas;
    this._rgba2Hex = rgbaToHex;

    // console.log(wheelCanvas, overlayCanvas);

    this.drawInnerOverlay(this.overlayCanvas, this.overlayCanvas.getContext('2d')!);
    this.drawOuterOverlay(this.overlayCanvas, this.overlayCanvas.getContext('2d')!);
  }

  public spinWheel = () => {
    if (this._isSpinning) this._increaseFactor = -0.001;
    else this._increaseFactor = 0.008;
    this._isSpinning = !this._isSpinning;

    if (this._isSpinning) {
      document.dispatchEvent(new CustomEvent('WHEEL_START_REQUESTED'));
    } else {
      document.dispatchEvent(new CustomEvent('WHEEL_STOP_REQUESTED'));
    }

    // console.log('this._isSpinning = ', this._isSpinning);
    // console.log('this._rotationAnimationID = ', this._rotationAnimationID);
    if (this._rotationAnimationID === -1) this.calcRotation();
  }

  private determineWinner = () => {
    for (let user of this.userList) {
      const rotation = Math.abs((this.radiansToDegrees(this.rotation) - 270)) % 360;

      // console.log('determine winner:');
      // console.log('rotation: ', rotation);
      // console.log('this.radiansToDegrees(user.startingAngle): ', this.radiansToDegrees(user.startingAngle));
      // console.log('this.radiansToDegrees(user.endingAngle): ', this.radiansToDegrees(user.endingAngle));

      if (rotation >= this.radiansToDegrees(user.startingAngle)
        && rotation < this.radiansToDegrees(user.endingAngle)) {
        return user;
      }
    }
  }

  private calcRotation = () => {
    this._rotationAnimationID = requestAnimationFrame(this.calcRotation);

    const maxVelocity = 1;

    let factor = this._increaseFactor;

    this.velocity += factor;
    if (this.velocity <= 0 && this._isSpinning === false) {
      cancelAnimationFrame(this._rotationAnimationID);
      this._rotationAnimationID = -1;
      this.velocity = 0;
    }
    if (this.velocity >= maxVelocity) this.velocity = maxVelocity;
    else if (this.velocity <= 0) {
      this._velocity = 0;
      document.dispatchEvent(new CustomEvent('WHEEL_STOPPED', {
        detail: {
          winner: this.determineWinner()
        }
      }));
    }

    // console.log('velo = ', this.velocity);
    // console.log('factor = ', factor);

    if (this._isSpinning) {
      this.rotation -= this.easeInExpo(this.velocity) / 5;
      this.globalAlpha = 1 - (this.easeInExpo(this.velocity) / 10) * 9;
      // console.log('transformed velo = ', this.easeInExpo(this.velocity) / 5);
    } else {
      this.rotation -= this.easeInExpo(this.velocity) / 5;
      this.globalAlpha = 0.2 + Math.abs(-0.8 + (this.easeInExpo(this.velocity) / 10) * 8);
      // console.log('transformed velo = ', this.easeOutExpo(this.velocity) / 5);
    }

    this.renderWheel();
  }

  private degreesToRadians(degrees: number) {
    return (degrees * Math.PI) / 180;
  }

  private radiansToDegrees(rad: number) {
    return (rad * 180) / Math.PI;
  }

  public renderWheel = () => {

    function sumTo(a: User[], i: number) {
      let sum = 0;
      for (let j = 0; j < i; j++) {
        sum += a[j].angle;
      }
      return sum;
    }

    function sumToLabel(a: User[], i: number) {
      if (!a[0]) return;
      let sum: number = a[0].angle / 2;
      for (let j: number = 0; j < i; j++) {
        sum += a[j].angle;
      }
      return sum;
    }

    const drawSegment = (canvas: HTMLCanvasElement, context: CanvasRenderingContext2D, i: number, color: string = this.COLOR_APO_PRIMARY) => {
      context.save();
      let centerX = Math.floor(canvas.width / window.devicePixelRatio / 2);
      let centerY = Math.floor(canvas.height / window.devicePixelRatio / 2);
      this.radius = Math.floor((canvas.width / window.devicePixelRatio - 40) / 2);

      let startingAngle = this.degreesToRadians(0);
      let arcSize = this.degreesToRadians(360);
      let endingAngle = (startingAngle + arcSize);

      if (i !== -1) {
        startingAngle = this.userList[i].startingAngle = this.degreesToRadians(sumTo(this.userList, i));
        arcSize = this.degreesToRadians(this.userList[i].angle);
        endingAngle = this.userList[i].endingAngle = (startingAngle + arcSize);
      }

      context.beginPath();
      if (i !== -1) {
        context.moveTo(centerX, centerY);
      }
      context.arc(centerX, centerY, this.radius,
                  startingAngle + this.rotation, endingAngle + this.rotation, false);
      context.closePath();

      // context.fillStyle = this._rainbowGradient[i % this._rainbowGradient.length];
      if (i !== -1) {
        context.fillStyle = 'rgba(' + Math.floor(colorPalette[i]._rgb[0]) + ', ' + Math.floor(colorPalette[i]._rgb[1]) + ', ' + Math.floor(colorPalette[i]._rgb[2]) + ')';
        // context.fillStyle = this._rainbowGradient[Math.floor(this._rainbowGradient.length / this.userList.length) * i];
      } else context.fillStyle = this.COLOR_APO_PRIMARY;
      context.fill();

      if (i !== -1 && this.userList.length > 1) {
        context.lineWidth = 2;
        context.strokeStyle = "white";
        context.stroke();
      }

      context.restore();

      drawSegmentLabel(canvas, context, i);
    }

    const drawSegmentLabel = (canvas: HTMLCanvasElement, context: CanvasRenderingContext2D, i: number) => {
      context.save();
      let x = Math.floor(canvas.width / window.devicePixelRatio / 2);
      let y = Math.floor(canvas.height / window.devicePixelRatio / 2);
      let angle = 0;
      if (i !== -1) {
        // @ts-ignore
        angle = this.degreesToRadians(sumToLabel(this.userList, i));
      }

      context.translate(x, y);
      context.rotate(angle + this.rotation);
      let dx = Math.floor((canvas.width / window.devicePixelRatio - 40) * 0.5) - 20;
      let dy = 5;

      context.fillStyle = "#ffffff";
      context.textAlign = "right";
      //context.color = '#fff';
      let fontSize = Math.floor((canvas.height / window.devicePixelRatio - 40) / 34);
      context.font = fontSize + "pt Helvetica";

      if (i !== -1) {
        context.fillText(this.userList[i].name, dx, dy);
      } else {
        context.fillText("Bitte füge Benutzer hinzu", 120, -120);
        // this.overlayCanvas.getContext('2d')!.clearRect(0, 0, this.overlayCanvas.width, this.overlayCanvas.height);
        // this.drawInnerOverlay(this.overlayCanvas, this.overlayCanvas.getContext('2d')!, true);
        // this.drawOuterOverlay(this.overlayCanvas, this.overlayCanvas.getContext('2d')!);
      }

      context.restore();
    }


    const colorPalette = distinctcolors({
                                          count: this._userList.length,
                                          hueMin: 230,
                                          hueMax: 267,
                                          chromaMin: 33,
                                          chromaMax: 75,
                                          lightMin: 55,
                                          lightMax: 88,
                                          quality: 250,
                                          samples: 400
                                        });

    const hexColors : any[] = [];
    colorPalette.forEach((color : any) => {
      hexColors.push(this._rgba2Hex('rgba(' + Math.floor(color._rgb[0]) + ', ' + Math.floor(color._rgb[1]) + ', ' + Math.floor(color._rgb[2]) + ')'))
    })

    // write users in divided sections into wheel canvas
    // console.log('rendering userlist as wheel');
    this.userList.forEach((el: User, index) => {
      // console.log('this.globalAlpha ', this.globalAlpha);
      el.winningColors = hexColors;
      this.wheelCanvas.getContext('2d')!.save();
      this.wheelCanvas.getContext('2d')!.globalAlpha = this.globalAlpha;
      drawSegment(this.wheelCanvas, this.wheelCanvas.getContext('2d')!, index, hexColors[index]);
      this.wheelCanvas.getContext('2d')!.restore();
      //ctx.globalCompositeOperation = "source-in";
    })

    if (this.userList.length === 0) drawSegment(this.wheelCanvas, this.wheelCanvas.getContext('2d')!, -1);

  }

  drawInnerOverlay = (canvas: HTMLCanvasElement, context: CanvasRenderingContext2D, noUser: boolean = false) => {
    context.save();
    let centerX = Math.floor(canvas.width / window.devicePixelRatio / 2);
    let centerY = Math.floor(canvas.height / window.devicePixelRatio / 2);
    this.radius = Math.floor((canvas.width / window.devicePixelRatio - 40) / 6);

    context.beginPath();
    if (!noUser) {
      context.moveTo(centerX, centerY);
    }
    context.arc(centerX, centerY, this.radius,
                this.degreesToRadians(-90), this.degreesToRadians(280), false);
    context.closePath();

    context.fillStyle = this.COLOR_A;
    context.fill();

    context.shadowOffsetX = 0;
    context.shadowOffsetY = 0;
    context.shadowColor = "rgba(0, 0, 0, 1)";
    context.shadowBlur = 20;

    context.lineWidth = 2;
    context.strokeStyle = "white";
    context.stroke();

    context.restore();
  }

  drawOuterOverlay = (canvas: HTMLCanvasElement, context: CanvasRenderingContext2D) => {
    context.save();
    let centerX = Math.floor(canvas.width / window.devicePixelRatio / 2);
    let centerY = Math.floor(canvas.height / window.devicePixelRatio / 2);
    this.radius = Math.floor((canvas.width / window.devicePixelRatio - 40) / 2);

    // console.log('draw outer overlay');

    context.beginPath();
    // context.moveTo(centerX, centerY);
    context.arc(centerX, centerY, this.radius + 8,
                this.degreesToRadians(-90), this.degreesToRadians(280), false);
    context.closePath();

    // context.fillStyle = '#ff0000';
    // context.fill();

    // context.shadowInset = true;
    context.shadowOffsetX = 0;
    context.shadowOffsetY = 0;
    context.shadowColor = 'black';
    context.shadowBlur = 7;

    context.lineWidth = 20;
    context.strokeStyle = this.COLOR_A;
    context.stroke();


    context.restore();
  }

  easeInExpo = (x: number): number => {
    return x === 0 ? 0 : Math.pow(2, 10 * x - 10);
  }
  easeOutExpo = (x: number): number => {
    return x === 1 ? 1 : 1 - Math.pow(2, -10 * x);
  }

  easeOutBack = (x: number): number => {
    const c1 = 1.70158;
    const c3 = c1 + 1;

    return 1 + c3 * Math.pow(x - 1, 3) + c1 * Math.pow(x - 1, 2);
  }
}
