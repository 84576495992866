import User from "./User";
import FirebaseManager from "./FirebaseManager";

export default class Splash {

  get el(): HTMLElement {
    return this._el;
  }

  private readonly _el: HTMLElement;

  constructor(el: HTMLElement) {
    this._el = el;
  }

  public clearEntries = () => {
    this._el.querySelectorAll('#add-user-form input').forEach(input => {
      (input as HTMLInputElement).value = '';
    });

    (this._el.querySelector('#add-user-form input')! as HTMLElement).focus();
  }

  public configSplash = (type: string, user?: User | null) => {
    this._el.querySelectorAll('.splash').forEach(splash => {
      (splash as HTMLElement)!.style.display = 'none'
    })

    switch (type) {
      case 'welcome':
        (this._el!.querySelector('[data-type="welcome"]') as HTMLElement)!.style.display = 'flex';
        break;
      case 'winner':
        if (user) this._el!.querySelector('[data-type="winner"] h2')!.innerHTML = user.name + ' hat gewonnen!';
        (this._el!.querySelector('[data-type="winner"]') as HTMLElement)!.style.display = 'flex';
        break;
      case 'db-connect':
        (this._el!.querySelector('[data-type="db-connect"]') as HTMLElement)!.style.display = 'flex';
        break;
      case 'db-register':
        (this._el!.querySelector('[data-type="db-register"]') as HTMLElement)!.style.display = 'flex';
        break;
      case 'user-area':
        // console.log('FirebaseManager.instance.userData = ', FirebaseManager.instance.userData);
        // console.log('FirebaseManager.instance.auth.currentUser = ', FirebaseManager.instance.auth.currentUser);
        if (FirebaseManager.instance.auth.currentUser) this._el!.querySelector(
          '[data-type="user-area"] h2')!.innerHTML = 'Hi ' + FirebaseManager.instance.auth.currentUser.displayName + ',';
        (this._el!.querySelector('[data-type="user-area"]') as HTMLElement)!.style.display = 'flex';
        break;
      case 'save-warning':
        (this._el!.querySelector('[data-type="save-warning"]') as HTMLElement)!.style.display = 'flex';
        break;
      case 'reset-warning':
        (this._el!.querySelector('[data-type="reset-warning"]') as HTMLElement)!.style.display = 'flex';
        break;
      case 'save-successful':
        (this._el!.querySelector('[data-type="save-successful"]') as HTMLElement)!.style.display = 'flex';
        break;
      case 'reset-successful':
        (this._el!.querySelector('[data-type="reset-successful"]') as HTMLElement)!.style.display = 'flex';
        break;
      case 'add-user':
        if (FirebaseManager.instance.userData) this._el!.querySelector('[data-type="add-user"] h2')!.innerHTML = 'Hi ' + FirebaseManager.instance.auth.currentUser.displayName + ',';
        (this._el!.querySelector('[data-type="add-user"]') as HTMLElement)!.style.display = 'flex';
        break;
    }
  }
}
