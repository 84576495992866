export default class AudioManager {

  static instance: AudioManager;
  private audioUrl: string = require('../../sound/california_games_1.wav');
  private audioContext = new AudioContext();
  private source = this.audioContext.createBufferSource();
  private gainNode = this.audioContext.createGain();
  private audioRequest = new XMLHttpRequest();
    constructor() {

      this.gainNode.gain.value = 0.05;
      this.gainNode.connect(this.audioContext.destination);
      this.source.connect(this.gainNode);

      this.audioRequest.open('GET', this.audioUrl, true);
      this.audioRequest.responseType = 'arraybuffer';

      if (AudioManager.instance) {
        return AudioManager.instance;
      }
      AudioManager.instance = this;
    }

    public loadBGM = () => {
      this.audioRequest.onload =  () => {
        this.audioContext.decodeAudioData(this.audioRequest.response, (response) => {
          this.source.buffer = response;
          this.source.loop = true;
          this.source.start(0);
        }, function () {
          console.error('The request failed.');
        });
      }
      this.audioRequest.send();
    }
}
