export default class User {
  public winningColors: string[] = ['#ffffff'];

  set numModerated(value: number) {
    this._numModerated = value;
  }

  get startingAngle(): number {
    return this._startingAngle;
  }

  set startingAngle(value: number) {
    this._startingAngle = value;
  }

  get endingAngle(): number {
    return this._endingAngle;
  }

  set endingAngle(value: number) {
    this._endingAngle = value;
  }

  get id(): string {
    return this._id;
  }

  get team(): string {
    return this._team;
  }

  get numModerated(): number {
    return this._numModerated;
  }

  get enabledUserList(): User[] {
    return this._enabledUserList;
  }

  get name(): string {
    return this._name;
  }

  get angle(): number {
    return this._angle;
  }

  set angle(value: number) {
    this._angle = value;
  }

  set isEnabled(bool: boolean) {
    this._isEnabled = bool;

    // console.log('call set isEnabled:');
    // console.log('enabledUserList = ', this.enabledUserList);
    // console.log('this = ', this);

    // @ts-ignore
    if (bool === true && !this.enabledUserList.includes(this)) {
      this.el?.classList.add("enabled");
      this.enabledUserList.push(this);
    } else if (bool === false && this.enabledUserList.includes(this)) {
      this.el?.classList.remove("enabled");
      // @ts-ignore
      const index = this.enabledUserList.findIndex(user => user === this);
      this.enabledUserList.splice(index, 1);
    }
  }


  get isEnabled() {
    return this._isEnabled;
  }

  get el(): HTMLElement | undefined {
    return this._el;
  }

  set el(value: HTMLElement) {
    this._el = value;
  }

  private _name: string;
  private _angle: number = 0;
  private _startingAngle: number = 0;
  private _endingAngle: number = 0;
  private _team: string;
  private _id: string;
  private _numModerated: number;
  private _el: HTMLElement | undefined;
  private _isEnabled: boolean = false;
  private _enabledUserList: User[];

  constructor(name: string, team: string, uid: string, numModerated: number, enabledUserList: User[]) {
    this._name = name;
    this._team = team;
    this._id = uid + '';
    this._numModerated = numModerated;
    // this._el = el;
    this._enabledUserList = enabledUserList;
  }
}
