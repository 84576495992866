import User from "./User";
import SpinningWheel from "./SpinningWheel";
import Splash from "./Splash";
import FirebaseManager from "./FirebaseManager";
import ListManager from "./ListManager";
import ApoWheelEvents from "./ApoWheelEvents";
import AudioManager from "./AudioManager";
import 'regenerator-runtime/runtime'
// @ts-ignore
import confetti from "canvas-confetti"

// DOM Elements
const saveReqButton: HTMLElement = document.querySelector(".splash button[data-type=\"save-request\" ]")!;
const saveButton: HTMLElement = document.querySelector(".splash button[data-type=\"save\" ]")!;
const resetReqButton: HTMLElement = document.querySelector(".splash button[data-type=\"reset-request\" ]")!;
const resetButton: HTMLElement = document.querySelector(".splash button[data-type=\"reset\" ]")!;
const logoutButton: HTMLElement = document.querySelector(".splash button[data-type=\"logout\" ]")!;
const wheelCanvas: HTMLCanvasElement = document.querySelector("#wheel-canvas")!;
const wheelButton: HTMLCanvasElement = document.querySelector(".wheel-button")!;
const dbConnectForm: HTMLCanvasElement = document.querySelector("#db-connect-form")!;
const dbRegisterForm: HTMLCanvasElement = document.querySelector("#db-register-form")!;
const overlayCanvas: HTMLCanvasElement = document.querySelector("#overlay-canvas")!;
const presentationWrapper: HTMLElement = document.querySelector(".presentation-wrapper")!;
const splashWrapper: HTMLElement = document.querySelector(".splash-wrapper")!;
const logoAnimation: HTMLVideoElement = document.querySelector('#logo-animation')!;
const addUserButton: HTMLVideoElement = document.querySelector('[data-type="add-user"]')!;
const addUserForm: HTMLCanvasElement = document.querySelector("#add-user-form")!;

// Classes
const splashManager = new Splash(splashWrapper);
const firebase = new FirebaseManager();
const listManager = new ListManager(firebase);
const audioManager = new AudioManager();

export default class App {

  private wheel: SpinningWheel;

  constructor() {
    listManager.blockList();

    this.adjustCanvasToDevicePixelRatio(wheelCanvas);
    this.adjustCanvasToDevicePixelRatio(overlayCanvas);

    this.wheel = new SpinningWheel(wheelCanvas, overlayCanvas, presentationWrapper.offsetWidth - 100, this.RGBAToHexA);

    logoAnimation.playbackRate = 0.4;

    this.registerListeners();

    setTimeout(() => {
      this.showSplash('welcome')
    }, 300);
  }

  private registerListeners = () => {
    window.addEventListener("beforeunload", (e : Event) => {
      if(FirebaseManager.instance.hasUnsyncedData) e.preventDefault();
    });

    splashWrapper.querySelectorAll('.btn:not([type=submit])').forEach(button => {
      button.addEventListener('click', this.hideSplash);
    })

    splashWrapper.querySelector('.btn[data-type="register"]')!.addEventListener('click', () => {
      this.showSplash('db-register');
    });
    splashWrapper.querySelector('.btn[data-type="login"]')!.addEventListener('click', () => {
      this.showSplash('db-connect');
    });

    addUserButton.addEventListener('click', () => {
      this.showSplash('add-user');
    });

    addUserForm.addEventListener('submit', (e) => {
      e.preventDefault();

      // console.log('add-user submit');

      const name = (((e.target as HTMLFormElement).querySelector('input[type=name]') as HTMLInputElement)!.value);
      const team = (((e.target as HTMLFormElement).querySelector('input[data-type=team]') as HTMLInputElement)!.value);
      const numModerated:number = parseInt(((e.target as HTMLFormElement).querySelector('input[data-type=num-moderated]') as HTMLInputElement)!.value);

      listManager.newEntry(name, team, numModerated).then(() => {
        splashManager.clearEntries();
      });
      //
      // firebase.saveFirebaseData(name, team, numModerated).then(() => {
      //   this.hideSplash();
      //   this.onFirebaseDataLoad(!!FirebaseManager.instance.auth.currentUser);
      // })
      return false;
    });

    dbRegisterForm.addEventListener('submit', (e) => {
      e.preventDefault();

      // console.log('db register submit');

      const name = (((e.target as HTMLFormElement).querySelector('input[type=name]') as HTMLInputElement)!.value);
      const email = (((e.target as HTMLFormElement).querySelector('input[type=email]') as HTMLInputElement)!.value);
      const password = (((e.target as HTMLFormElement).querySelector('input[type=password]') as HTMLInputElement)!.value);

      firebase.registerWithFirebase(name, email, password).then(() => {
        this.hideSplash();
        this.onFirebaseDataLoad(!!FirebaseManager.instance.auth.currentUser);
      })
      return false;
    });

    dbConnectForm.addEventListener('submit', (e) => {
      e.preventDefault();

      // console.log('db connect submitted');

      const email = (((e.target as HTMLFormElement).querySelector('input[type=email]') as HTMLInputElement)!.value);
      const password = (((e.target as HTMLFormElement).querySelector('input[type=password]') as HTMLInputElement)!.value);

      firebase.authenticateWithFirebase(email, password).then(() => {
        this.hideSplash();
      })
      return false;
    });
    listManager.dbConnectOpenModalButton.addEventListener('click', this.openDBConnectSplash);

    saveReqButton.addEventListener('click', async () => {
      // console.log('saving userlist: listManager.userList = ', listManager.userList);
      this.showSplash('save-warning');

      await saveClicked(saveButton);

      firebase.saveFirebaseData(listManager.userList).then(() => {
        this.showSplash('save-successful');
      })
    });

    async function saveClicked(btn: HTMLElement): Promise<void> {
      return new Promise((resolve, reject) => {
        btn.onclick = () => resolve();
      })
    }

    async function resetClicked(btn: HTMLElement): Promise<void> {
      return new Promise((resolve, reject) => {
        btn.onclick = () => resolve();
      })
    }

    //
    resetReqButton.addEventListener('click', async () => {
      this.showSplash('reset-warning');

      await resetClicked(resetButton);

      this.wheel.userList = listManager.userList = [];
      listManager.enabledUserList = [];
      listManager.populateList();
      listManager.showEnabled();
      this.hideSplash();
      this.unloadUserUI();
    });

    logoutButton.addEventListener('click', () => {
      firebase.logoutFirebase().then(() => {
        this.wheel.userList = listManager.userList = [];
        listManager.enabledUserList = [];
        listManager.populateList();
        this.hideSplash();
        this.unloadUserUI();
      })
    });
    wheelButton.addEventListener('click', this.wheel.spinWheel);
    document.addEventListener(ApoWheelEvents.WHEEL_STOPPED, this.onWheelStop);
    document.addEventListener(ApoWheelEvents.WHEEL_STOP_REQUESTED, listManager.blockList);
    document.addEventListener(ApoWheelEvents.WHEEL_START_REQUESTED, listManager.unblockList);
    document.addEventListener(ApoWheelEvents.USER_SELECTION_CHANGED, (e: Event) => {
      this.wheel.userList = (e as CustomEvent).detail.enabledUserList;
    })
    document.addEventListener(ApoWheelEvents.FIREBASE_DATA_CHANGED, (e: Event) => {
      this.onFirebaseDataLoad(!!FirebaseManager.instance.auth.currentUser);
    })
  }

  private adjustCanvasToDevicePixelRatio = (canvas: HTMLCanvasElement) => {
    canvas.width = presentationWrapper.offsetWidth - 50;
    canvas.height = presentationWrapper.offsetHeight;
    // canvas.style.position = 'relative';
    canvas.style.left = '25px';

    let ctx: CanvasRenderingContext2D = canvas.getContext("2d")!;
    let rect = canvas.getBoundingClientRect();

    canvas.width = rect.width * window.devicePixelRatio;
    canvas.height = rect.height * window.devicePixelRatio;

    ctx.scale(window.devicePixelRatio, window.devicePixelRatio);

    canvas.style.width = rect.width + 'px';
    canvas.style.height = rect.height + 'px';
  }

  private onWheelStop = (e: Event) => {
    // console.log('wheel stop - data = ', e);

    this.showSplash('winner', (e as CustomEvent).detail.winner)
    confetti({
      particleCount: 300,
      spread: 120,
      origin: {y: 0.6},
      colors: (e as CustomEvent).detail.winner.winningColors
    });
  }



  private RGBAToHexA(rgba : string, forceRemoveAlpha = false) {
    return "#" + rgba.replace(/^rgba?\(|\s+|\)$/g, '') // Get's rgba / rgb string values
      .split(',') // splits them at ","
      .filter((string, index) => !forceRemoveAlpha || index !== 3)
      .map(string => parseFloat(string)) // Converts them to numbers
      .map((number, index) => index === 3 ? Math.round(number * 255) : number) // Converts alpha to 255 number
      .map(number => number.toString(16)) // Converts numbers to hex
      .map(string => string.length === 1 ? "0" + string : string) // Adds 0 when length of one number is 1
      .join("") // Puts the array to togehter to a string
  }

  private hideSplash = (e?: Event) => {
    // console.log('hidesplash')
    splashWrapper.classList.remove('active');
    listManager.unblockList();

    if (!e) return;

    // console.log('e.currentTarget!.dataset.type = ', e.currentTarget);
    if (((e.currentTarget as HTMLElement).parentNode as HTMLElement)!.dataset.type === 'welcome') {
      audioManager.loadBGM();
    }
  }
  private showSplash = (splashType: string, user?: User) => {
    // const splashType = (e.currentTarget as HTMLElement).getAttribute('data-type');
    // console.log('showsplash : type = ', splashType, user);
    if (splashType) {
      splashManager.configSplash(splashType, user);
      splashWrapper.classList.add('active');
    } else {
      splashWrapper.classList.remove('active');
    }
  }

  private loadUserUI = () => {
    addUserButton.style.display = 'flex';
    listManager.dbConnectOpenModalButton.querySelector('span')!.innerHTML = 'User Area';

  }
  private unloadUserUI = () => {
    addUserButton.style.display = 'none';
    listManager.dbConnectOpenModalButton.querySelector('span')!.innerHTML = 'DB-Connect';
  }
  private openDBConnectSplash = () => {
    if (firebase.auth.currentUser) {
      splashManager.configSplash('user-area');
      splashWrapper.classList.add('active');
    } else {
      this.showSplash('db-connect');
    }
  }

  private onFirebaseDataLoad = (hasCurrentUser: boolean) => {
    // console.log('app: onfirebasedataload');

    listManager.populateList();
    listManager.showEnabled();
    if (hasCurrentUser) {
      this.loadUserUI();
    }
  }
}
