// @ts-ignore
import {initializeApp} from 'firebase/app';
// @ts-ignore
import {getDatabase, ref, child, set, get, DataSnapshot, Database} from 'firebase/database';
// @ts-ignore
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  setPersistence,
  browserSessionPersistence,
  AuthCredential,
  UserCredential,
  onAuthStateChanged,
  updateProfile
  // @ts-ignore
} from "firebase/auth";
import ApoWheelEvents from "./ApoWheelEvents";
import User from "./User";

export default class FirebaseManager {
  set hasUnsyncedData(value: boolean) {
    this._hasUnsyncedData = value;
  }
  public get hasUnsyncedData(): boolean {
    return this._hasUnsyncedData;
  }
  get userData(): any {
    return this._userData;
  }

  set userData(value: any) {
    this._userData = value;
  }

  get dbRef(): Database {
    return this._dbRef;
  }

  set dbRef(value: Database) {
    this._dbRef = value;
  }

  public get auth(): AuthCredential {
    return this._auth;
  }

  set auth(value: AuthCredential) {
    this._auth = value;
  }

  public get usersData(): any {
    return this._usersData;
  }

  set usersData(value: any) {
    this._usersData = value;
  }


  static instance: FirebaseManager;
  private readonly _firebaseConfig = {
    apiKey: "AIzaSyBCl1sShVUX7IBpz8rKnVV3axIyvZwJwe8",
    authDomain: "apocomretrowheel.firebaseapp.com",
    databaseURL: "https://apocomretrowheel-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "apocomretrowheel",
    storageBucket: "apocomretrowheel.appspot.com",
    messagingSenderId: "596240924599",
    appId: "1:596240924599:web:9f5ad3e7d3a93f43e33232"
  };

  private _usersData: any;
  private _userData: any;
  private _app = initializeApp(this._firebaseConfig);
  private _database = getDatabase(this._app);
  private _dbRef = ref(this._database);
  private _auth = getAuth();
  private _hasUnsyncedData : boolean = false;

  constructor() {
    // console.log('yo firebase app = ', this._app);
    // console.log('firebase db = ', this._database);
    //
    onAuthStateChanged(this._auth, (user: UserCredential) => {
      // console.log('auth state changed- user = ', user);

      if (user) {
        // const uid = user.uid;
        this.loadFirebaseData().then(() => {
          document.dispatchEvent(new CustomEvent(ApoWheelEvents.FIREBASE_DATA_CHANGED, {
            detail: {
              currentUser: this.auth.currentUser
            }
          }));
        })
          .catch(() => {

          });
      } else {
        document.dispatchEvent(new CustomEvent(ApoWheelEvents.FIREBASE_DATA_CHANGED, {
          detail: {
            currentUser: null
          }
        }));
      }
    })

    if (FirebaseManager.instance) {
      return FirebaseManager.instance;
    }
    FirebaseManager.instance = this;
  }

  public loadFirebaseData = (): Promise<boolean> => {

    console.log('load user data... uid -> ', this.auth.currentUser.uid);

    return new Promise((resolve, reject) => {

      get(child(this.dbRef, `/users/${this.auth.currentUser.uid}/`)).then((snapshot: DataSnapshot) => {
        if (snapshot.exists()) {
          this.userData = snapshot.val();
          // console.log("this.userData = ", this.userData.users);
          this.usersData = this.userData.users;
          resolve(!!this.auth.currentUser);
        } else {
          console.log("No data available");
          reject();
        }
      }).catch((error: any) => {
        console.error('yomama', error);
        reject();
      });

    });
  }

  public registerWithFirebase = (displayName: string, email: string, password: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      const auth = getAuth();
      createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential: UserCredential) => {

          const user = userCredential.user;
          // console.log('firebase user created - user = ', user);

          updateProfile(auth.currentUser, {
            displayName
          }).then(() => {
            // Profile updated!
            // console.log('firebase user profile updated - user = ', user);
            this.saveFirebaseData([]).then(() => {
              // console.log('firebase user data saved successfully');

              resolve();
            });
          }).catch(() => {
            reject();
          });

        })
        .catch((/*error: any*/) => {
          // const errorCode = error.code;
          // const errorMessage = error.message;
          reject();
        });
    })
  }

  public logoutFirebase = (): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      signOut(this.auth).then(() => {
        // Sign-out successful.

        this.usersData = [];
        resolve(!!this.auth)

      }).catch(() => {
        // An error happened.
        reject();
      });
    });
  }

  public saveFirebaseData = (userList: User[]): Promise<boolean> => {
    // return;
    return new Promise((resolve, reject) => {

      // console.log('this.dbRef = ' + this.dbRef);
      // console.log('trying to save data to /users/' + this.auth.currentUser.uid);

      let fbUserList: {}[] = [];
      userList.forEach((user: User) => {
        fbUserList.push({
                          "id": user.id,
                          "name": user.name,
                          "numModerated": user.numModerated,
                          "team": user.team
                        })
      })

      set(child(this.dbRef, `/users/${this.auth.currentUser.uid}/`), {
        email: this.auth.currentUser.email,
        users: fbUserList
      }).then(() => {
        this.hasUnsyncedData = false;
        resolve(!!this.auth.currentUser);
      })
        .catch((error: any) => {
          console.error('yomama', error);
          reject();
        });

    });
  }

  public authenticateWithFirebase = (email: string, password: string): Promise<AuthCredential> => {
    // console.log('email: ', email);
    // console.log('password: ', password);
    // console.log('authenticating with firebase...');

    return new Promise((resolve, reject) => {
      setPersistence(this.auth, browserSessionPersistence)
        .then(() => {
          // Existing and future Auth states are now persisted in the current
          // session only. Closing the window would clear any existing state even
          // if a user forgets to sign out.
          // ...
          // New sign-in will be persisted with session persistence.
          return signInWithEmailAndPassword(this.auth, email, password);
        }).then((userCredential: UserCredential) => {
        // Signed in
        const user = userCredential.user;
        console.log('successfully loged in - user = ', user);
        // console.log('yo firebase auth = ', auth);
        this.loadFirebaseData().then(() => {
          resolve(!!this.auth)
        });
      })
        .catch(() => {
          // Handle Errors here.
          reject();
        });
      // return false;
    })
  }
}
